<script>
import Layout from "@/router/layouts/auth";
import appConfig from "@/app.config";
import UserService from "@/api/services/user.service.js";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import i18n from "@/i18n";

// custom validator for datafile Data Record Start Row
const acceptedTermsValidation = (value) => {
  return value === true ? true: false ;
};

/**
 * Create Account
 */
export default {
  page: {
    title: "Create Account",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      firstName: null,
      lastName: null,
      password: null,
      confirmPassword: null,
      termsAccept: false,
      newsLetterAccept: false,
      /**** Operational variables ****/
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      submit: false,
      isTermsNotAcceptedError: false,
      isDisplayExpandNewsLetter: false,
      isDisplayExpandTerms: false,
      /**** Supportive variables ****/
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English"
        },
        {
          flag: require("@/assets/images/flags/germany.jpg"),
          language: "de",
          title: "Deutsch"
        }
      ],
      lan: i18n.locale,
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAs: sameAs("password") },
    termsAccept: {acceptedTermsValidation}
  },

  mounted() {
    const companyActivationLink = this.$route.query.activate;
    if (companyActivationLink) {
      this.dismissCountDownSuccess = this.dismissSecsSuccess;
    }
  },
  methods: {
    clickRegSubmit() {
      this.submit = true;

      // stop here if form is invalid
      this.$v.$touch();

      // if validation success
      if (this.$v.$invalid) {
        this.isRegisterError = true;
      } else if(!this.$v.termsAccept.acceptedTermsValidation) {
        this.isRegisterError = true;
      } else {
        this.updateUser();
      }
    },
    async updateUser() {
      this.tryingToRegister = true;

      const userId = this.$route.query.uid;
      let userUpdateDTO = {
        userId,
        name: {
          first: this.firstName,
          last: this.lastName
        },
        password: this.password,
        hasAcceptedTerms: this.termsAccept,
        hasAcceptNewsLetter: this.newsLetterAccept
      };

      try {
        await UserService.updateUserOnActivation(userUpdateDTO);
        this.registerSuccess = true;
      } catch(error) {
        this.isRegisterError = true;
      }
    },
    redirectToLogin() {
      this.$router.push("/login");
    },
    setLanguage(locale) {
      this.lan = locale;
      i18n.locale = locale;
      window.localStorage.setItem("locale", locale);
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-6">
        <div class="card overflow-hidden">
          <div class="bg-gradient-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-white p-4">
                  <h5 class="text-white">
                    {{ $t("createAccount.header.title") }}
                  </h5>
                  <p>{{ $t("createAccount.header.description") }}</p>
                </div>
              </div>
              <div class="col-5 align-self-center">
                <img
                  src="@/assets/images/register-image.png"
                  alt
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/Redem Icon.png" alt height="38" />
                </span>
              </div>
            </div>

            <b-alert v-model="registerSuccess" class="mt-3" variant="success">
              {{  $t("createAccount.form.successMsg") }}
            </b-alert>

            <b-alert
              v-model="isTermsNotAcceptedError"
              class="mt-3"
              variant="danger"
              dismissible
            >
              {{ $t("createAccount.form.termsNotAcceptedErrorMsg") }}
            </b-alert>

            <b-alert
              v-model="isRegisterError"
              class="mt-3"
              variant="danger"
              dismissible
              >{{ $t("createAccount.form.failedMsg") }}</b-alert
            >

            <b-form class="p-2" @submit.prevent="clickRegSubmit" v-if="!registerSuccess">
              <!-- Name row -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="name-group" label-for="firstName">
                    <template v-slot:label>
                      {{ $t("createAccount.form.firstName.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="firstName"
                      v-model="firstName"
                      type="text"
                      :placeholder="
                        $t('createAccount.form.firstName.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.firstName.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.firstName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.firstName.required">{{
                        $t("createAccount.form.firstName.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group id="name-group" label-for="lastName">
                    <template v-slot:label>
                      {{ $t("createAccount.form.lastName.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="lastName"
                      v-model="lastName"
                      type="text"
                      :placeholder="
                        $t('createAccount.form.lastName.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.lastName.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.lastName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.lastName.required">{{
                        $t("createAccount.form.lastName.required")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <!-- Password -->
              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="password-group" label-for="password">
                    <template v-slot:label>
                      {{ $t("createAccount.form.password.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="password"
                      v-model="password"
                      type="password"
                      :placeholder="
                        $t('createAccount.form.password.placeholder')
                      "
                      :class="{ 'is-invalid': submit && $v.password.$error }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.password.required">{{
                        $t("createAccount.form.password.requiredErrorMsg")
                      }}</span>
                      <span v-if="!$v.password.minLength">{{
                        $t("createAccount.form.password.minLengthErrorMsg")
                      }}</span>
                    </div>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group id="password-group" label-for="confirmPassword">
                    <template v-slot:label>
                      {{ $t("createAccount.form.confirmPassword.label") }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="confirmPassword"
                      v-model="confirmPassword"
                      type="password"
                      :placeholder="
                        $t('createAccount.form.confirmPassword.placeholder')
                      "
                      :class="{
                        'is-invalid': submit && $v.confirmPassword.$error
                      }"
                    ></b-form-input>
                    <div
                      v-if="submit && $v.confirmPassword.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.confirmPassword.required">{{
                        $t(
                          "createAccount.form.confirmPassword.requiredErrorMsg"
                        )
                      }}</span>
                      <span v-if="!$v.confirmPassword.sameAsPassword">{{
                        $t(
                          "createAccount.form.confirmPassword.notMatchErrorMsg"
                        )
                      }}</span>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <!-- NewsLetter accept -->
              <div class="custom-control custom-checkbox mt-4">
                <input
                  id="customControlForNewsLetterInline"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="newsLetterAccept"
                />
                <label
                  class="custom-control-label"
                  for="customControlForNewsLetterInline"
                >
                  {{ $t("createAccount.form.newsLetter.text") }}
                  <i
                    class="bx bxs-info-circle ml-1"
                    @click="showMoreNewsLetter()"
                    style="cursor: pointer"
                  ></i>
                </label>

                <div>
                   {{$t("registration.form.newsLetter.supportText.text1")}} <b> {{$t("registration.form.newsLetter.supportText.text2")}} </b> {{$t("registration.form.newsLetter.supportText.text3")}} <b> {{$t("registration.form.newsLetter.supportText.text4")}}</b> {{$t("registration.form.newsLetter.supportText.text5")}}
                </div>

                <p v-if="isDisplayExpandNewsLetter">
                  <small>
                    {{ $t("createAccount.form.newsLetter.expandText") }}
                    <a href="https://redem.io/privacy-policy" target="_blank"
                      >Privacy Policy</a
                    >
                  </small>
                </p>
              </div>

              <!-- Terms accept -->
              <div class="custom-control custom-checkbox mt-4">
                <input
                  id="customControlInline"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="termsAccept"
                  :class="{ 'is-invalid': submit && $v.termsAccept.$error }"
                />
                <label class="custom-control-label" for="customControlInline">
                  {{ $t("createAccount.form.terms.text1") }}
                  <a
                    href="https://redem.io/terms-and-conditions"
                    target="_blank"
                    >{{ $t("createAccount.form.terms.text2") }}</a
                  >
                  {{ $t("createAccount.form.terms.text3")
                  }}<span class="text-danger">*</span>
                  <i
                    class="bx bxs-info-circle ml-1"
                    @click="showMoreTerms()"
                    style="cursor: pointer"
                  ></i>
                </label>
                <p v-if="isDisplayExpandTerms">
                  <small>
                    {{ $t("createAccount.form.terms.expandText") }}
                  </small>
                </p>
              </div> 

              <!-- Button -->
              <div class="mt-4">
                <b-button type="submit" variant="success" class="btn-block">{{
                  $t("createAccount.form.createButton")
                }}</b-button>
              </div>
            </b-form>

            <div v-if="registerSuccess">
              <b-button
                variant="success"
                class="btn-block"
                @click="redirectToLogin()"
                >{{ $t("createAccount.form.redirectToLoginButton") }}</b-button
              >
            </div>
          </div>
        </div>

        <!-- Other Links -->
        <div class="mt-5 text-center">
          <p>
            {{ $t("createAccount.footer.text1") }}
            <router-link
              tag="a"
              to="/login"
              class="font-weight-medium text-info"
              >{{ $t("createAccount.footer.text2") }}</router-link
            >
          </p>
          <p>
            © {{ new Date().getFullYear() }} Redem GmbH | made with
            <i class="mdi mdi-heart text-danger"></i> in Austria
          </p>
        </div>

        <!-- language Selector -->
        <div class="mt-3 text-center">
          <span
            class="mr-2"
            @click="setLanguage(languages[0].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[0].flag" height="10" />
            {{ languages[0].title }}
          </span>
          &bull;
          <span
            class="ml-2"
            @click="setLanguage(languages[1].language)"
            style="cursor: pointer"
          >
            <img class="mr-1" :src="languages[1].flag" height="10" />
            {{ languages[1].title }}
          </span>
        </div>
      </div>
      
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
